import React, {useState} from "react"
import Layout from "../../components/layout"
import emailjs from "emailjs-com"
import './contact.css'
import Seo from "../../components/seo"

const Contact = (props) => {
  const [displayMessage, setDisplayMessage] = useState(false);

const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1z5oglw', 'template_av7ddyh', e.target, 'BfnuTp06LWnhy0ugY')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
  
        e.target.reset()
        setDisplayMessage(true)
        setTimeout(function() {
          setDisplayMessage(false)
          }, 4000);
    };

  return (
    <>
      <Seo title="Contact" description="If you would like to get more information about oboe lessons, 
      or if you want to book me for a concert, please contact me via de contact form"/>
      <Layout>
        <section className="main main-contact">
          <div className="left">
            <h2 className="sub-title">Let's stay in touch!</h2>
              <p>
                Don't hesitate to contact me if you have any questions regarding oboe lessons or concerts.
                Please fill out the form and I'll get back to you as soon as possible.
              </p>
          </div>
              <div className="right">
                  <form className="contact-form" onSubmit={sendEmail}>
                      <input aria-label="name" className="input" type="text" name="name" placeholder="Name" required />
                      <input aria-label="email" className="input" type="email" name="email" placeholder="Email" required />
                      <input aria-label="subject" className="input" type="text" name="subject" placeholder="Subject" maxLength="30" required />
                      <textarea aria-label="textarea" className="input" rows="5" cols="30" name="message" placeholder="What's your question?" maxLength="200" required>
                      </textarea>
                      <button aria-label="send form" className="contact-btn" type="submit" name="contact-btn">Send</button>
                  </form>
                  { displayMessage && <div className="succes-message">Thanks you! Your mail has succesfully been sent to q.rychner@gmail.com</div> }
                  
              </div>
        </section>
      </Layout>
    </>
  )
}

export default Contact
